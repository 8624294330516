import React from "react";
import "./Header.scss";
import BurgerMenu from "assets/svgs/burgerMenu";
import cheggl from "assets/images/ChegglLogo.png";
import whiteLogo from "assets/images/WhiteLogo.png";
import SearchInput from "components/Search/Search";
import SearchIcon from "assets/svgs/search";
import Button from "components/Button/Button";
import ButtonArrow from "assets/svgs/buttonArrow";
import UserSvg from "assets/svgs/userSvg";
import HeartSvg from "assets/svgs/heartSvg";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
const Header = ({ homeHeader, isSticky }) => {
  const location = useLocation();
  const history = useHistory();
  const isHomePage = location.pathname === "/";
  const user = localStorage.getItem("user");
  const handleClick = () => {
    if (user) {
      localStorage.clear();
      history.push("/login");
    } else {
      history.push("/login");
    }
  };
  return (
    <div
      style={{ display: isHomePage && !homeHeader ? "none" : "flex" }}
      className={`${homeHeader ? "home-header" : "main-header"} ${
        isSticky ? "sticky" : ""
      }`}
    >
      <div className="left">
        <div className="burgerMenu">
          <BurgerMenu
            fill={homeHeader ? (isSticky ? "" : "#fff") : "#000000"}
          />
          <div className="main-menu">
            <Link to="/login">Login</Link>
            <Link to="/registration">Registrieren</Link>
            <a href="https://cheggl.info/marketing">Video bestellen</a>
            {/* <Link href="#">Werben auf Cheggl</Link> */}
            <Link to="/newsletter">Newsletter</Link>
            <Link to="/kontakt">Kontakt</Link>
            <a href="https://cheggl.info/job-openings">Jobs bei Cheggl</a>
          </div>
        </div>
        <div className="logo">
          <Link to="/">
            {" "}
            <img
              src={homeHeader ? (isSticky ? cheggl : whiteLogo) : cheggl}
              alt="cheggl Logo"
            />
          </Link>
        </div>
      </div>
      <div className="right">
        <div className="headerSearch">
          <SearchInput classIcon="left" icon={<SearchIcon />} />
        </div>
        <div className="divider"></div>
        <div className="companyButton">
          <Button
            iconPosition="right"
            text={`${user ? "Für Unternehmen" : "Für Unternehmen"} `}
            icon={<ButtonArrow />}
            onClick={() => {
              if (!user) {
                history.push("/registration");
              } else if (JSON.parse(user)?.role === "admin") {
                history.push("/admin/unternehmen");
              } else {
                if (JSON.parse(user)?.companies[0]) {
                  history.push(
                    `/unternehmen/stammdaten/${JSON.parse(user)?.companies[0]}`
                  );
                } else {
                  history.push("/onboarding");
                }
              }
            }}
          />
        </div>
        {!location?.pathname?.includes("login") && (
          <div className="user-icon">
            <div className="dropdown">
              <UserSvg
                className="dropbtn"
                fill={homeHeader ? (isSticky ? "" : "#fff") : "#5315B5"}
              />
              <div className="dropdown-content">
                {user && (
                  <>
                    <span>
                      Hi, <b>{JSON.parse(user)?.fullName}</b>
                    </span>
                    <hr />
                  </>
                )}
                <span onClick={handleClick}> {user ? "Logout" : "Login"}</span>
                <hr />
              </div>
            </div>
          </div>
        )}
        {/* <div className="wishlist-icon">
          <HeartSvg width="27" height="27"  fill={homeHeader ? (isSticky ? "#5315b5" : "#fff") : "#5315B5"} />
        </div> */}
      </div>
    </div>
  );
};

export default Header;
